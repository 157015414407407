<script setup lang="ts">
import { onMounted, reactive } from 'vue';
import { getUserInfo } from '@/apis';
import { useGenerateStore } from "@/store/modules/generate";
import EE from '@/utils/event/eventManager';
import Login from '@/components/login.vue';
import Pay from '@/components/pay.vue';
import { getUserDeviceId } from "@/utils/device.js";
import {  actionStatistic } from "@/apis";
import { useRouter, useRoute } from "vue-router";
import {getUrlParam} from "@/utils/string"
import * as LZString  from 'lz-string';
const generateStore = useGenerateStore();
const route = useRoute();
let { from, bd_vid, qhclickid } = route.query;
const router = useRouter();
router.isReady().then(()=>{
  from = route.query.from;
  bd_vid = route.query.bd_vid;
  qhclickid = route.query.qhclickid;
  if(bd_vid){
    localStorage.setItem('bd_vid',bd_vid)
  }
  if(qhclickid){
    localStorage.setItem('qhclickid',qhclickid)
  }
  if(from){
    localStorage.setItem('from',from)
  }
})
let tenant_key = getUrlParam('api_key');
let user_key = getUrlParam('uuid');
let tenant_token = getUrlParam('token');
let tenantUserObj = {
  id:tenant_key+'_'+user_key,
  is_vip:true,
  token:tenant_token,
  uuid:user_key,
  apikey:tenant_key
}
if(tenant_key){
  sessionStorage.setItem('tenant_key',tenant_key.slice(0,16))
  sessionStorage.setItem('tenant_obj',JSON.stringify(tenantUserObj))
  generateStore.setTenant_key(tenant_key.slice(0,16));
  generateStore.setTenant_obj(tenantUserObj)
  generateStore.changeUserInfo(tenantUserObj)
}


const view = document.createElement('div');
document.body.appendChild(view);
const states = reactive({
    needLogin: false,
    needPay: false,
    fn: null,
})

function loginSuccess(){
  init()
}
function close(){
    states.needLogin = false;
    EE.emit("loginClose");
}
async function init(){
  let data = localStorage.getItem('token');
  if(!data){
    console.log('需要登录');
  } else {
    data = JSON.parse(data);
    const { data: info } = await getUserInfo();
    states.needLogin = false;
    console.log('***info', info);
    if(info?.uuid){
      generateStore.generate.isLogin = true;
      generateStore.changeUserInfo(info);
      states.fn && states.fn();
      states.fn = null;
      EE.emit('loginSuccess');
    } else {
      localStorage.removeItem('token')
    }
  }
}

async function paySuccess(){
  console.log('paySuccess');
  states.needPay = false;
  const { data: info } = await getUserInfo();
  if(info?.uuid){
    generateStore.changeUserInfo(info);
  }
  states.fn && states.fn();
}
function closePay(){
  states.needPay = false;
}
onMounted(async() =>{
  await init();
  EE.on('needLogin', (fn) => {
    states.fn = fn;
    states.needLogin = true;
    actionStatistic({
      action: "login_dialog_show",
      user_id: generateStore.generate.userInfo?.id || "",
      device_id: getUserDeviceId(),
      from:localStorage.getItem('from')||from,
    });
  });
  EE.on('needLogout', (fn) => {
    localStorage.removeItem('token');
    generateStore.changeUserInfo(null);
    generateStore.generate.isLogin = false;
    fn();
  });

  EE.on('needPay', (fn) => {
    states.fn = fn;
    states.needPay = true;
    actionStatistic({
      action: "pay_dialog_show",
      user_id: generateStore.generate.userInfo?.id || "",
      device_id: getUserDeviceId(),
      from:localStorage.getItem('from')||from,
    });
  });
  
})
</script>

<template>
  <div>
    <router-view />
    <login 
      v-if="states.needLogin"
      @close="close"
      @success="loginSuccess"
    />
    <pay 
      v-if="states.needPay"
      @close="closePay"
      @success="paySuccess"
    />
  </div>
</template>

<style scoped>
</style>
